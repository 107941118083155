<template>
  <div>
    <div class="row align-items-stratch">
      <div class="col-3 my-3">
        <!-- <label for="">Image</label> -->
        <button class="w-100 h-100 btn border border-success">..</button>
        <input type="file" id="photo" hidden class="form-control" />
      </div>

      <div class="col-9 row align-items-end">
        <div class="col-7 my-3">
          <label for="">Désignation</label>
          <input type="text" v-model="item.fullName" class="form-control" />
        </div>

        <div class="col-5 my-3">
          <label for="">Catégorie</label>
          <select class="form-select" v-model="item.section_reference">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
        </div>

        <div class="col-12 my-3">
          <label for="">Description</label>
          <textarea v-model="item.description" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-3">
        <label for="">Mots Cle</label>
        <textarea v-model="item.description" class="form-control"></textarea>
      </div>
      <div class="col-auto my-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="false"
            v-model="item.slaughtering"
            id="flexCheckSlaughter"
          />
          <label class="form-check-label" for="flexCheckSlaughter">
            Abattage
          </label>
        </div>
      </div>

      <div class="col-auto my-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="false"
            v-model="item.producting"
            id="flexCheckProduction"
          />
          <label class="form-check-label" for="flexCheckProduction">
            Production
          </label>
        </div>
      </div>

      <div class="col-auto my-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="false"
            v-model="item.oujdafood_display"
            id="flexCheckSlaughter"
          />
          <label class="form-check-label" for="flexCheckSlaughter">
            Affciher sur Oujdafood.ma
          </label>
        </div>
      </div>

      <div class="col-auto my-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :value="false"
            v-model="item.website_display"
            id="flexCheckProduction"
          />
          <label class="form-check-label" for="flexCheckProduction">
            Afficher sur Goodgroupe.com
          </label>
        </div>
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(item)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // item: {},
    };
  },
  computed: {
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("item", {
      item: "getItem",
    }),
  },
  methods: {
    async save(data) {
      let item = data;
      await this.$confirm({
        message: "Vous voulez Modifier l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("item/update", data);
          }
        },
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/show", this.$route.params.reference);
  },
};
</script>
